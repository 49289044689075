import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import ConfirmOfficer from '@presenters/web/pages/Delegation/ManageDelegationRoles/ConfirmOfficer';
import SelectOfficer from '@presenters/web/pages/Delegation/ManageDelegationRoles/SelectOfficer';

import {
  CLUB_TYPE_ROTARY,
  DISTRICT_TYPE,
  REGIONAL_GROUP_TYPE,
} from '@domain/districts';

import { getDelegationRolesPath } from '@use-cases/clubs';
import { useModal, useStopBrowserNavigate } from '@use-cases/districts';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { getOrgTypeLocation } from '@utils/getOrgTypeLocation';
import { getOrgTypeName } from '@utils/getOrgTypeName';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DIS2 } from '@typings/dis2';

interface DelegateRoleProps extends RouteComponentProps {
  orgId: string;
  roleId: string;
  delegatorId: string;
  orgType: string;
  date: string;
  delegationRoles: DIS2.DelegationRoles[];
  setUserActionType: React.Dispatch<
    React.SetStateAction<'Add' | 'Edit' | 'Delete' | null>
  >;
}

const DelegateRole: React.FC<DelegateRoleProps> = ({
  orgId,
  roleId,
  delegatorId,
  orgType,
  date,
  delegationRoles,
  setUserActionType,
}) => {
  const { t } = useTranslation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [, setIsFieldTouched] = useState<boolean>(false);
  const [isBackDelegationRolesPage, setIsBackDelegationRolesPage] = useState<
    boolean
  >(false);

  const [startDate, endDate] = date.split('_');

  const isFirstStep = (step: number) => step === 0;
  const isLastStep = (step: number) => step === 1;

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const stepBack = (step: number, setStep: (step: number) => void) => {
    isFirstStep(step)
      ? localizedNavigate(getDelegationRolesPath())
      : setStep(step - 1);
  };

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();
    if (isBackDelegationRolesPage) {
      localizedNavigate(getDelegationRolesPath());
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    if (isLastStep(step)) {
      show(true);
    } else {
      stepBack(step, setStep);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setTimeout(() => {
      setIsFieldTouched(isTouched);
    }, 0);
  };

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
    setIsBackDelegationRolesPage(true);
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && isFirstStep(step)) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={() => {
          globalHide();
          setIsBackDelegationRolesPage(false);
        }}
        onConfirm={() => {
          leavePage(step, setStep);
          window.scrollTo(0, 0);
        }}
      />
    );
  };

  const handleSubmit = async () => {
    setUserActionType('Add');
    localizedNavigate(getDelegationRolesPath());
  };

  const [roleDetails] = delegationRoles
    ? delegationRoles.filter((role, index) => `${role.Key}${index}` === roleId)
    : [];

  const roleTranslated = roleDetails && getClubRoleTValue(t, roleDetails.Role);
  const orgTypeTranslated = orgType && getOrgTypeName(t, orgType);
  const orgName =
    roleDetails &&
    getOrgTypeLocation(roleDetails.OrganizationType, roleDetails.Organization);
  const roleKey = roleDetails?.RoleKey;

  return (
    <Wizard
      pages={[SelectOfficer, ConfirmOfficer]}
      initialValues={{
        orgId,
        roleId,
        delegatorId,
        roleKey,
        roleName: roleTranslated,
        orgType: orgTypeTranslated,
        orgName,
        isClubRole: orgType === CLUB_TYPE_ROTARY,
        isDistrictRole: orgType === DISTRICT_TYPE,
        isRegionalGroupRole: orgType === REGIONAL_GROUP_TYPE,
        delegatee: null,
        slot: {
          start: moment(startDate),
          end: moment(endDate),
        },
        regionDistrictSelected: '',
      }}
      onSubmit={handleSubmit}
      handleBackArrowClick={handleBackArrowClick}
      handleFormFieldsTouched={handleFormFieldsTouched}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default DelegateRole;
